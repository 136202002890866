import React from "react";
import Calculation from "../Calculation/Calculation";
import PaymentTerms from "../PaymentTerms/PaymentTerms";
import ButtonsContainer from "../ButtonsContainer/ButtonsContainer";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    paper: {
        // minWidth: "90%",
        maxWidth: '940px',
        padding:"0",
    },
}));


export default function ModalCalculation(props) {
    const classes = useStyles();
    const { data, handleSuccessMessage, handleShowCalculation, handleEmailErrorMessage } = props;

    const handleClose = () => {
        handleShowCalculation(false);
    };

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            classes={{ paper: classes.paper }}
        >
            <DialogContent className={classes.paper}>
                <Calculation data={data} />
                <PaymentTerms />
                <ButtonsContainer
                    handleEmailErrorMessage={handleEmailErrorMessage}
                    handleSuccessMessage={handleSuccessMessage}
                    onClose={handleClose}
                />
            </DialogContent>
        </Dialog>
    )
}
