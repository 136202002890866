import React from "react";
import styles from "./ButtonsContainer.module.scss";
import {T} from "../../localization/translate";


export default function ButtonsContainer(props) {
    const { handleSuccessMessage, handleEmailErrorMessage, onClose } = props;
    return (
        <div className={styles.buttonWrapper}>
            {/*<button*/}
            {/*    className={'btn btn-primary'}*/}
            {/*>*/}
            {/*    <a*/}
            {/*        target="_blank"*/}
            {/*        rel="noreferrer"*/}
            {/*        href="https://iaa.am/form-store/am?_token=fHnUZcQAyNrcKfQYGobHHONoEjIeyE4fpOX5ZDpo&type=calculatorWeb"*/}
            {/*        className={styles.link}*/}
            {/*    >*/}
            {/*        {translate("download")}*/}
            {/*    </a>*/}
            {/*</button>*/}
            {/*<ModalEmail handleSuccessMessage={handleSuccessMessage} handleEmailErrorMessage={handleEmailErrorMessage} />*/}
            <button onClick={onClose} className={'btn btn-primary'}>{T("close")}</button>
        </div>
    )
}
