import React from "react";
import {NavLink} from "reactstrap";

import {T} from "../../localization/translate";

const Section = () => {
  return (
    <React.Fragment>
      <section className="bg-home4 overflow-hidden" id="home">
        <div className="position-relative header-info" style={{ zIndex: "1" }}>
          <div>
            <h1 className="mb-4">
              {T('calculateOnlineWithoutOurSupport')}
            </h1>
            <p className="fs-17">
              {T('ourOnlineCalc')}
            </p>
            <div className="mt-4 pt-2" style={{width: 150, margin: '0 auto'}}>
              <NavLink href="#calculator" className="btn btn-primary">
                {T('calculate')}
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1440"
            height="150"
            preserveAspectRatio="none"
            viewBox="0 0 1440 250"
          >
            <g mask='url("#SvgjsMask1036")' fill="none">
              <path
                d="M 0,214 C 96,194 288,120.8 480,114 C 672,107.2 768,201.4 960,180 C 1152,158.6 1344,41.6 1440,7L1440 250L0 250z"
                fill="rgba(255, 255, 255, 1)"
              ></path>
            </g>
            <defs>
              <mask id="SvgjsMask1036">
                <rect width="1440" height="250" fill="#ffffff"></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Section;
