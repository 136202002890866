import styles from "./SubmitButton.module.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Button} from "reactstrap";
import React from "react";
import {T} from "../../localization/translate";

const SubmitButton = ({handleSubmit, isLoading, text, style = null, className=''}) => {
    return (
        <Button
            type="button"
            onClick={handleSubmit}
            className={`btn btn-primary ${styles.submitBtn} ${className}`}
            disabled={isLoading}
            style={style}
        >
            {text || T("submit")}
            {isLoading && <CircularProgress className={styles.loaderStyle}/>}
        </Button>
    )
}

export default SubmitButton;
