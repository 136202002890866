import React from "react";
import copart from "../../assets/images/copart.png";
import iaai from "../../assets/images/iaai.svg";
import styles from "./AuctionButtons.module.scss";

export default function AuctionButtons(props) {
    const {handlShowCalculator, auctionLabel} = props;

    return (
        <div className={styles.auctionBoxWrapper}>
            <div
                onClick={() => handlShowCalculator("iaai")}
                className={(auctionLabel === "iaai") ? `${styles.auctionBox} ${styles.active}` : `${styles.auctionBox}`}
            >
                {/*<p className={styles.text}>IAAI</p>*/}
                <div className={styles.imgWrapper}
                >
                    <img src={iaai} alt="auction company" className={styles.img}/>
                </div>
            </div>
            <div
                onClick={() => handlShowCalculator("copart")}
                className={(auctionLabel === "copart") ? `${styles.auctionBox} ${styles.active}` : `${styles.auctionBox}`}
            >
                {/*<p className={styles.text}>COPART</p>*/}
                <div className={styles.imgWrapper}
                >
                    <img src={copart} alt="auction company" className={styles.img}/>
                </div>
            </div>
            {/*<div*/}
            {/*    // onClick={() => handleShowConatcts("others")}*/}
            {/*    className={(auctionLabel === "others") ? `${styles.auctionBox} ${styles.active}` : `${styles.auctionBox}`}*/}
            {/*>*/}
            {/*    */}
            {/*</div>*/}
            {/*<NavLink href="#contact">*/}
            {/*    <p className={styles.text}>{translate("OTHERS")}</p>*/}
            {/*</NavLink>*/}
        </div>
    )
}
