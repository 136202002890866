import React from "react";
import logo from "../assets/images/metaMotors.jpeg";
import {Col, Row, NavLink} from "reactstrap";
import {Link} from "react-router-dom";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import {T} from "../localization/translate";

const ListCard = (props) => {
    return (
        <React.Fragment>
            <div className="col-lg-2 col-md-12">
                <div className={props.isShow ? "mt-4 ps-0" : "mt-4"}>
                    <h6 className="text-white text-uppercase fs-16">{props.title}</h6>
                    <ul className="list-unstyled footer-link mt-3 mb-0">
                        {props.isChildList.map((Item, key) => (
                            <li key={key}>
                                <NavLink href={`#${Item.id}`}>
                                    {Item.subList}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
}

const ListIconData = [
    {
        iconClass: "mdi mdi-facebook",
        link: 'https://www.facebook.com/MetaMotorsLLC'
    },
    {
        iconClass: "mdi mdi-instagram",
        link: 'https://www.instagram.com/metamotors.am'
    },
    // {
    //     iconClass: "mdi mdi-pinterest",
    // },
    // {
    //     iconClass: "mdi mdi-twitter",
    // },
];

const Footer = () => {
    const ListData = [
        {
            title: T('sections'),
            isList: true,
            isShow: true,
            isChildList: [
                {id: 'services', subList: T('services')},
                {id: 'statistics', subList: T('statistics')},
                {id: 'process', subList: T('ourProcess')},
                {id: 'calculator', subList: T('calculator')},
                {id: 'contact', subList: T('contacts')},
                {id: 'about', subList: T('aboutUs')},
            ],
        },
        // {
        //   title: "Help",
        //   isList: false,
        //   isShow: false,
        //   isChildList: [
        //     { id: 1, subList: "Sign Up" },
        //     { id: 2, subList: "Login" },
        //     { id: 3, subList: "Careers" },
        //     { id: 4, subList: "Privacy Policy" },
        //   ],
        // },
    ];

    return (
        <React.Fragment>
            <footer className="bg-footer">
                {/*<Container fluid={true}>*/}
                <Row>
                    <Col lg={2} className={'first_column_space'}/>
                    <Col lg={2} md={12}>
                        <div className="mt-4">
                            <NavLink
                                to="/" onClick={() => window.scrollTo({behavior: 'smooth', top: 0})}
                                style={{padding: 0}}
                            >
                                <img src={logo} alt="" height="70"/>
                            </NavLink>
                            {/*<p className="text-white-50 mt-3 pt-2 mb-0 ">*/}
                            {/*    It is a long established fact that a reader will be of a page*/}
                            {/*    reader will be of at its layout.*/}
                            {/*</p>*/}
                            <div className="mt-4">
                                <ul className="list-inline footer-social">
                                    {/* Footer Icon Component Start */}
                                    {ListIconData.map((item, key) => (
                                        <li className="list-inline-item" key={key}>
                                            <a href={item.link} target="_blank" rel="noreferrer noopener">
                                                <i className={item.iconClass}/>
                                            </a>
                                        </li>
                                    ))}
                                    {/* Footer Icon Component End */}
                                </ul>
                            </div>
                        </div>
                    </Col>

                    {/* Footer List Start*/}

                    {ListData.map((item, key) => (
                        <ListCard
                            key={key}
                            isList={item.isList}
                            isShow={item.isShow}
                            title={item.title}
                            isChildList={item.isChildList}
                        />
                    ))}
                    {/* Footer List End*/}

                    <Col lg={6} md={12}>

                        <YMaps className={'yandex_map'}>
                            <Map
                                state={{center: [40.174796, 44.517460], zoom: 17}}
                                height="100%"
                                width="100%"
                            >
                                <Placemark
                                    geometry={[40.174796, 44.517460]}
                                />
                            </Map>
                        </YMaps>
                    </Col>
                </Row>
                {/*</Container>*/}
            </footer>
        </React.Fragment>
    );
}

export default Footer;
