import { configureStore } from "@reduxjs/toolkit";
import dataSlice from "./slices/dataSlice";
import languageSlice from "./slices/languagSlice";

export default configureStore({
  reducer: {
    data: dataSlice,
    language: languageSlice
  },
});
