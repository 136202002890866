import React, {useEffect, useState} from "react";
import Calculator from "../Calculator/Calculator";
import ModalCalculation from "../ModalCalculation/ModalCalculation";
import CustomAlert from "../CustomAlert/CustomAlert";
import checkValuesExist from "../helpers/checkValuesExist";
import {containsOnlyNumbers} from "../helpers/regexp";
import {useSelector} from "react-redux";
import {descriptionOptionsSelector} from "../../store/slices/dataSlice";
import {thousandSeparator} from "../../utils";

const CAR_PRICE = "carPrice";
const ENGINE_VOLUME = "engineVolume";
const OFFSITE = "offsite";

const initialValues = {
    carPrice: {value: "", category: "Car Price"},
    shipping: {value: "", category: "Shipping"},
    engineVolume: {value: "", category: "Engine, cm³"},
    auctionVenue: null,
    manufacturedYear: {value: ""},
    engineType: {value: ""},
    carType: {value: ""},
    offsite: {value: "false"},
};

export default function FormContacts(props) {
    const [calcData, setCalcData] = useState(null);
    const [params, setParams] = useState(initialValues);
    const [showCalculation, setShowCalculation] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });
    const [loading, setLoading] = useState(false);
    const {showCalculator, handleSuccessMessage, handleEmailErrorMessage, auctionLabel} = props;
    const state = useSelector(state => state.data);
    // console.log('params', params)
    const { ageOptions, ageOptionsForElectricalCars } = useSelector(state => state.data)
    const descriptionOptions = {
        "location": state.locationOptions,
        "manufacturedYear": params.engineType.value === 'electrical' ? ageOptionsForElectricalCars : ageOptions,
        "engineType": state.fuelTypeOptions,
        "carType": state.vehicleTypeOptions,
    };

    console.log('descriptionOptions', descriptionOptions)

    useEffect(() => {
        handleLocation(null)
    }, [auctionLabel])

    const handleChange = ({target: {value, name}}, option = "") => {
        if (name === CAR_PRICE) {
            if (containsOnlyNumbers(value) && value.length <= 10) {
                setParams({...params, [name]: {...params[name], value}});
            }
            if (!containsOnlyNumbers(value) && !value) {
                setParams({...params, [name]: {...params[name], value}});
            }
        } else if (name === ENGINE_VOLUME) {
            if (containsOnlyNumbers(value) && value.length <= 5) {
                setParams({...params, [name]: {...params[name], value}});
            }
            if (!containsOnlyNumbers(value) && !value) {
                setParams({...params, [name]: {...params[name], value}});
            }
        } else if (name === OFFSITE) {
            const offsiteFactor = option ? 100 : 0;
            setParams({
                ...params,
                [name]: {...params[name], value: option ? "true" : "false"},
                shipping: { ...params.shipping, value: params.auctionVenue ? thousandSeparator(+params.auctionVenue.price + offsiteFactor) : params.shipping.value }
            });
        } else {
            const selectedOption = descriptionOptions[option].find((item) => item.value === value);

            if (option === 'engineType') {
                setParams({...params, [name]: selectedOption, 'manufacturedYear': {value: ""}});
            } else {
                setParams({...params, [name]: selectedOption});
            }
        }
    };

    const handleLocation = (option) => {
        if (option) {
            const offsiteFactor = params.offsite.value === "true" ? 100 : false;
            const shippingValue = thousandSeparator(+option.price + offsiteFactor);
            setParams({
                ...params,
                auctionVenue: option,
                shipping: {...params.shipping, value: shippingValue}
            });
        } else {
            setParams({
                ...params,
                shipping: {value: "", category: "Shipping"},
                auctionVenue: null,
            })
        }
    };

    const handleSubmit = () => {
        const valuesExist = checkValuesExist(params);
        const yearIssue = params.manufacturedYear.label === ageOptions[3].label;

        if (!valuesExist ||  yearIssue) {
            setFormErrorMessage({...formErrorMessage, open: true, message: yearIssue ? 'selectManufactureYearError' : ''});
        } else {
            setLoading(true);
            console.log('params', params)
            try {
                const reqData = [];
                const keys = {...params}
                keys.auctionType = {value: auctionLabel};
                delete keys.shipping

                for (const property in keys) {
                    const encodedKey = encodeURIComponent(property);
                    const encodedValue = encodeURIComponent(keys[property].value);
                    reqData.push(encodedKey + "=" + encodedValue);
                }

                fetch(process.env.REACT_APP_API_URL + '/calculate', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: reqData.join("&")
                }).then(res => res.json()).then((data) => {
                    setLoading(false);
                    setCalcData(data);
                    setShowCalculation(true);
                })
            } catch (e) {
                setLoading(false)
            }
        }
    };

    const handleFormError = (open) => {
        setFormErrorMessage({...formErrorMessage, open})
    };

    return (
        <>
            {
                !!showCalculator && (
                    <Calculator
                        handleLocation={handleLocation}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        params={params}
                        loading={loading}
                        handleShowCalculation={setShowCalculation}
                        auctionLabel={auctionLabel}
                    />
                )
                // <Contacts/>
            }
            {
                calcData && showCalculator && showCalculation &&
                < >
                    < ModalCalculation
                        data={calcData}
                        handleSuccessMessage={handleSuccessMessage}
                        handleEmailErrorMessage={handleEmailErrorMessage}
                        handleShowCalculation={setShowCalculation}
                    />
                </>
            }
            {
                formErrorMessage.open && <CustomAlert onClose={handleFormError}
                                                      vertical={formErrorMessage.vertical}
                                                      horizontal={formErrorMessage.horizontal}
                                                      message={formErrorMessage.message || "formMessage"}
                                                      severity="error"
                                                      millisecond={3500}
                />
            }
        </>
    )
}
