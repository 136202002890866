import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import suv from "../../assets/images/suv.png";
import pickup from "../../assets/images/pickup.jpg"
import sedan from "../../assets/images/sedan.svg";
import motorcycle from "../../assets/images/motorcycle.png";
import styles from "./Select.module.css";
import {T} from "../../localization/translate";

const icons = [ motorcycle, sedan, pickup, suv ];
const VEHICLE_TYPE = "carType";

export default function SelectComponent(props) {
    const { value, name, label, handleChange, style, options, hidden } = props;

    return (
        <FormControl className={style} style={hidden ? {display: 'none'} : {}}>
            <InputLabel id="demo-simple-select-label">{T(label)}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                name={name}
                onChange={handleChange}
                label={T(label)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                {
                    options.map((option, index) => {
                        if (name === VEHICLE_TYPE) {
                            return (
                                <MenuItem value={option.value}
                                          key={index}
                                >
                                    <div className={styles.imgWrapper}>
                                        {T(option.label)}
                                        <img src={icons[index]} width="30" alt="vehicleType" className ={styles.img}/>
                                    </div>
                                </MenuItem>)
                        }
                        return <MenuItem value={option.value}
                                         key={index}
                        >{T(option.label)}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    )
}
