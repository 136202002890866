import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {T} from "../../localization/translate";


const useStyles = makeStyles({
        underline: {
            "&&&:before": {
                borderBottom: "1px solid #FFFFFF"
            },
            "&&:after": {
                borderBottom: "1px solid #FFFFFF"
            },
        },
        input: {
            "&::placeholder": {
                fontSize: "12px",
            },
        },

    })
;

const Input = (props) => {
    const classes = useStyles();
    const { disabled, value, name, style, label, placeholder } = props;
    return (
        <TextField
            id="standard-full-width"
            disabled={disabled}
            value={value}
            label={T(label)}
            name={name}
            className={style}
            placeholder={T(placeholder)}
            InputLabelProps={
                !value ?
                    { style: { transform: "translate(0, 1.5px) scale(1)" }, shrink: true } :
                    { style: { transform: "translate(0, 1.5px) scale(0.75)" }, shrink: true }
            }
            InputProps={{ classes }}
        />
    );
}

export default Input;
