import {createDraftSafeSelector, createSlice} from "@reduxjs/toolkit";
import {addLanguageToLocalStorage, getLanguageFromLocalStorage} from "../../components/helpers/localStorage";
import {translationKeys} from "../../localization/translationKeys";
import {LOCALES} from "../../localization/locales";

const initialState = {
    selectedLanguage: getLanguageFromLocalStorage() || LOCALES.AM,
    translationKeys
}

const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.selectedLanguage = action.payload;
            addLanguageToLocalStorage(action.payload)
        }
    },
});

export const {setLanguage} = languageSlice.actions;

export const selectedLanguageKeys = createDraftSafeSelector(
    state => state.language,
    languageState => languageState.translationKeys[languageState.selectedLanguage]
)

// export const selectedLanguageKeys = (state) => state.language.translationKeys[state.language.selectedLanguage]

export default languageSlice.reducer;

