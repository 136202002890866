import React, {useEffect} from "react";
import './App.css'
import "./assets/scss/theme.scss";
import {useDispatch} from "react-redux";
import {getCountryListData} from "./store/slices/dataSlice";
import Layout from "./Layout/Index";
import {getLanguageFromLocalStorage} from "./components/helpers/localStorage";
import {LOCALES} from "./localization/locales";
import {setLanguage} from "./store/slices/languagSlice";

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCountryListData())

        if (!getLanguageFromLocalStorage()) {
            fetch(process.env.REACT_APP_API_URL + '/ip').then(res => res.json()).then(res => {
                const country = LOCALES[res?.location?.country] || LOCALES.RU;

                if (country) {
                    dispatch(setLanguage(country))
                }
            })
        }
    }, [])

    return <Layout/>;
}

export default App;
