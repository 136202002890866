export const addLanguageToLocalStorage = (language) => {
    localStorage.setItem("language", JSON.stringify(language))
}

export const getLanguageFromLocalStorage = () => {
    const lang =  localStorage.getItem("language")

    if (lang) return JSON.parse(lang);
    return null
};
