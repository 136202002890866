import React, {useRef, useState} from "react";
import {Col, Container, Row, Label, Input, FormGroup} from "reactstrap";
import CustomAlert from "./CustomAlert/CustomAlert";
import {isValidEmail} from "./helpers/regexp";
import {T} from "../localization/translate";

const PHONE_NUMBER = "+374 96 995550";
const PHONE_NUMBER_RUSSIA = "+1 (445) 545-4554";
const SUPPORT_EMAIL = "info.metamotors@gmail.com";

const Card = (props) => {
    return (
        <React.Fragment>
            <Col lg={3} md={6}>
                <div className="contact-info text-center mt-5">
                    {
                        props.href
                            ? <a href={props.href}><div className="icon"><i className={props.iconClass}/></div></a>
                            : <div className="icon"><i className={props.iconClass}/></div>
                    }
                    <div className="mt-4 pt-2">
                        <h6 className="fs-17">{props.title}</h6>
                        {props.isChildItem.map((item, key) => (
                            <p className="text-muted mb-0" key={key}>
                                {item}
                            </p>
                        ))}
                    </div>
                </div>
            </Col>
        </React.Fragment>
    );
};

const Contact = () => {
    const [isLoading, setLoading] = useState(false);
    const formRef = useRef();

    const ContactData = [
        {
            title: T('mainOffice'),
            iconClass: "mdi mdi-map-marker text-primary h4",
            isChildItem: [T("officeAddress")],
            href: "javascript: window.scrollTo(0, document.body.scrollHeight);",
        },
        {
            title: T('Phone'),
            iconClass: "mdi mdi-phone text-primary h4",
            isChildItem: [PHONE_NUMBER, PHONE_NUMBER_RUSSIA],
            href: `tel:${PHONE_NUMBER}`,
        },
        {
            title: T('email'),
            iconClass: "mdi mdi-email text-primary h4",
            isChildItem: [SUPPORT_EMAIL],
            href: `mailto: ${SUPPORT_EMAIL}`,
        },
        {
            title: T('WorkingHours'),
            iconClass: "mdi mdi-calendar-clock text-primary h4",
            isChildItem: [`${T('MondayFriday')}: 9:00-06:00`, T('Saturday-Sunday: Holiday')],
        },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target
        const keys = {
            name: form[0].value,
            email: form[1].value,
            phone: form[2].value,
            comment: form[3].value
        }

        if (!keys.name || !isValidEmail(keys.email) || !keys.phone) {
            setFormErrorMessage({...formErrorMessage, open: true});
        } else {
            const reqData = [];

            for (const property in keys) {
                const encodedKey = encodeURIComponent(property);
                const encodedValue = encodeURIComponent(keys[property]);
                reqData.push(encodedKey + "=" + encodedValue);
            }

            fetch(process.env.REACT_APP_API_URL + '/contact-us', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: reqData.join("&")
            }).then(res => res.json()).then(req => {
                if (req.status === 200) {
                    setSuccessMessage({...successMessage, open: true})
                    formRef.current?.reset()
                }
            })
        }
    }

    const [formErrorMessage, setFormErrorMessage] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });

    const [ successMessage, setSuccessMessage ] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });

    const handleFormError = (open) => {
        setFormErrorMessage({...formErrorMessage, open})
    };

    const handleSuccessMessage = (open) => {
        setSuccessMessage({ ...successMessage, open });
    };

    const handleChange = ({target: {value, name}}, option = "") => {
        // if (name === CAR_PRICE) {
        //   if (containsOnlyNumbers(value) && value.length <= 10) {
        //     setParams({...params, [name]: {...params[name], value}});
        //   }
        //   if (!containsOnlyNumbers(value) && !value) {
        //     setParams({...params, [name]: {...params[name], value}});
        //   }
        // } else if (name === ENGINE_VOLUME) {
        //   if (containsOnlyNumbers(value) && value.length <= 5) {
        //     setParams({...params, [name]: {...params[name], value}});
        //   }
        //   if (!containsOnlyNumbers(value) && !value) {
        //     setParams({...params, [name]: {...params[name], value}});
        //   }
        // } else {
        //   const selectedOption = descriptionOptions[option].find((item) => item.value === value);
        //   setParams({...params, [name]: selectedOption});
        // }
    };

    return (
        <React.Fragment>
            <section className="section" id="contact">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={7}>
                            <div className="text-center mb-4">
                                {/*<p className="text-uppercase text-muted mb-2">{T('contact')}</p>*/}
                                <h3 className="text-uppercase">{T('getInTOUCH')}</h3>
                                <div className="title-border mt-3"/>
                                {/*<p className="title-desc text-muted mt-3">*/}
                                {/*    We craft digital, graphic and dimensional thinking, to create*/}
                                {/*    category leading brand experiences that have meaning and add a*/}
                                {/*    value.*/}
                                {/*</p>*/}
                            </div>
                        </Col>
                    </Row>

                    {/*  */}

                    <Row className="justify-content-center">
                        {/* Contact Component Start */}

                        {ContactData.map((value, idx) => (
                            <Card
                                key={idx}
                                title={value.title}
                                iconClass={value.iconClass}
                                isChildItem={value.isChildItem}
                                href={value.href}
                            />
                        ))}
                        {/* Contact Component End */}
                    </Row>

                    <Row className="mt-5 pt-2 justify-content-center">
                        <Col lg={9}>
                            <div className="custom-form">
                                <form action="src/components/Contact#" name="myForm" onSubmit={handleSubmit} ref={formRef}>
                                    <p id="error-msg"></p>
                                    <Row>
                                        <Col lg={12}>
                                            <FormGroup>
                                                <div className="mb-3">
                                                    <Label for="name" className="form-label">
                                                        {T('name')}* :
                                                    </Label>
                                                    <Input
                                                        name="name"
                                                        id="name"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={T('enterName')}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <div className="mb-3">
                                                    <Label for="email" className="form-label">
                                                        {T('email')}* :
                                                    </Label>
                                                    <Input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        id="email"
                                                        placeholder={T('enterEmail')}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>

                                        <Col lg={6}>
                                            <FormGroup>
                                                <div className="mb-3">
                                                    <Label for="number" className="form-label">
                                                        {T('Phone')}* :
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        name="number"
                                                        id="number"
                                                        placeholder={T('enterPhone')}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>

                                        <Col lg={12}>
                                            <FormGroup>
                                                <div className="mb-3">
                                                    <Label for="comments" className="form-label">
                                                        {T('comments')} :
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        className="form-control"
                                                        placeholder={T('enterComment')}
                                                        name="comments"
                                                        id="comments"
                                                        style={{height: "100px"}}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}>
                                            <FormGroup>
                                                <div className="mt-3 text-end">
                                                    <input
                                                        type="submit"
                                                        id="submit"
                                                        name="send"
                                                        className="submitBnt btn btn-primary"
                                                        value={T('sendMessage')}
                                                    />
                                                    <div id="simple-msg"/>
                                                </div>

                                                {/*<SubmitButton isLoading={isLoading} handleSubmit={handleSubmit} text={'Send Message'}/>*/}

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/*todo Add this globally*/}
                {
                    successMessage.open &&
                    <CustomAlert onClose={handleSuccessMessage}
                                 vertical={successMessage.vertical}
                                 horizontal={successMessage.horizontal}
                                 message="successMessage"
                                 severity="success"
                                 millisecond={3500}
                    />
                }
                {
                    formErrorMessage.open && <CustomAlert onClose={handleFormError}
                                                          vertical={formErrorMessage.vertical}
                                                          horizontal={formErrorMessage.horizontal}
                                                          message="formMessage"
                                                          severity="error"
                                                          millisecond={3500}
                    />
                }
            </section>
        </React.Fragment>
    );
};

export default Contact;
