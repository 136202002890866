import React, {useState} from "react";
import styles from "./LanguageMenu.module.css";
import {Menu, MenuItem, Button} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setLanguage} from "../../store/slices/languagSlice";

export const languageData = [
    {
        label: "Հայ",
        value: "am",
    },
    {
        label: "Ру",
        value: "ru",
    },
    {
        label: "EN",
        value: "en",
    },
];

export default function LanguageMenu(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const {open = false} = props;
    const selectedLanguage = useSelector(state => state.language.selectedLanguage);
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenuItem = (option) => {
        dispatch(setLanguage(option.value))
        setAnchorEl(null);
    };

    return (
        <div className={`${styles.languageWrapper} ${props.className}`}>
            <Button aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={open ? styles.toggleBtn : styles.btn}
            >
                {selectedLanguage}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={open ? {
                    vertical: "bottom",
                    horizontal: "left",
                } : {
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={open ? {
                    vertical: "top",
                    horizontal: "right",
                } : {
                    vertical: "top",
                    horizontal: "center",
                }}
                PaperProps={open ? {
                    style: {
                        width: 120,
                    },
                } : ""}
            >
                {
                    languageData.map((option, index) => {
                        return (
                            <MenuItem
                                key={index}
                                onClick={() => handleClickMenuItem(option)}
                                selected={option.value === selectedLanguage}
                            >
                                {option.label}
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </div>
    )
}
