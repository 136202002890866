import React from "react";

import Statistics from "../components/Statistics";
import BackToTop from "../components/BackToTop";
import Contact from "../components/Contact";
import Process from "../components/Process";
import Services from "../components/Services";
import Layout4 from "../pages/Layout4/Layout4";
import Calculator from '../components/Calculator'
import Footer from "./Footer";
import FooterAlt from "./FooterAlt";
import AboutUs from "../components/AboutUs";
import {useSelector} from "react-redux";

const Layout = (props) => {
    const lang = useSelector(state => state.language.selectedLanguage);

    return (
        <div className={`lang_${lang}`}>
            <Layout4/>
            <Services/>
            <Statistics/>
            <Process/>
            <Calculator props={props}/>
            <Contact/>
            <AboutUs/>
            <Footer/>
            <FooterAlt/>
            <BackToTop/>
        </div>
    );
};

export default Layout;
