import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {T} from "../../localization/translate";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomAlert(props) {

    const { onClose, vertical, horizontal, message, severity, millisecond } = props;

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        onClose(false);
    };

    return (
        <Snackbar
            open={true}
            autoHideDuration={millisecond}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
        >
            <Alert onClose={handleClose} severity={severity}>
                {T(message)}
            </Alert>
        </Snackbar>
    )
}
