import React, {useState, useEffect} from "react";
import {Collapse, Container, NavbarToggler, NavLink} from "reactstrap";
import Scrollspy from "react-scrollspy";

//import images
import logoLight from "../assets/images/logo-light.png";
import logoDark from "../assets/images/metaMotors.jpeg";
import logoOnly from '../assets/images/metaMotors_Icon.png';
import {Link} from "react-router-dom";
import LanguageMenu from "../components/LanguageMenu/LanguageMenu";
import {T} from "../localization/translate";

const NavBar = ({isDark}) => {
    const [isOpenMenu, setisOpenMenu] = useState(false);
    const [navClass, setnavClass] = useState("");

    const toggle = () => setisOpenMenu(!isOpenMenu);

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("nav-sticky");
        } else {
            setnavClass("");
        }
    }

    const isDarkClass = isDark ? "navbar-light" : "";

    return (<React.Fragment>
      <span>
        <nav
            className={"navbar navbar-expand-lg fixed-top " + isDarkClass + " sticky " + navClass}
            id="navbar"
        >
          <Container>
             <NavLink href="#calculator" className={'navbar_calculator_icon'}>
               <i className="mdi mdi-calculator"/>
             </NavLink>
            <NavLink
                className="navbar-brand logo text-uppercase" to="/"
                onClick={() => window.scrollTo({behavior: 'smooth', top: 0})}
                style={{padding: 0}}
            >
              {isDark ? (<>
                  <img
                      src={logoDark}
                      className="logo-light"
                      alt=""
                      height="70"
                  />
                  <img
                      src={logoOnly}
                      className="logo-dark"
                      alt=""
                      height="54"
                  />
              </>) : (<>
                  <img
                      src={logoDark}
                      className="logo-dark"
                      alt=""
                      height="70"
                  />
                  <img
                      src={logoLight}
                      className="logo-light"
                      alt=""
                      height="70"
                  />
              </>)}
            </NavLink>
            <NavbarToggler
                className="navbar-toggler"
                type="button"
                onClick={toggle}
            >
              <i className="mdi mdi-menu"/>
            </NavbarToggler>
            <Collapse
                isOpen={isOpenMenu}
                className="navbar-collapse"
                id="navbarNav"
            >
              <Scrollspy
                  offset={-70}
                  items={["home", "services", "statistics", "process", "calculator", "contact", 'about',]}
                  currentClassName="active"
                  className="navbar-nav ms-auto navbar-center"
                  id="mySidenav"
              >
                <li className="nav-item d-none">
                    <NavLink href="#home">{T('services')}</NavLink>
                </li>
                <li className="nav-item" onClick={() => setisOpenMenu(false)}>
                  <NavLink href="#services">{T('services')}</NavLink>
                </li>
                 <li className="nav-item" onClick={() => setisOpenMenu(false)}>
                  <NavLink href="#statistics">{T('statistics')}</NavLink>
                </li>
                <li className="nav-item" onClick={() => setisOpenMenu(false)}>
                  <NavLink href="#process">{T('ourProcess')}</NavLink>
                </li>
                <li className="nav-item" onClick={() => setisOpenMenu(false)}>
                  <NavLink href="#calculator">{T('calculator')}</NavLink>
                </li>
                  <li className="nav-item" onClick={() => setisOpenMenu(false)}>
                  <NavLink href="#contact">{T('contacts')}</NavLink>
                </li>
                  <li className="nav-item" onClick={() => setisOpenMenu(false)}>
                  <NavLink href="#about">{T('aboutUs')}</NavLink>
                </li>
                  <LanguageMenu className={'mobile_burger'}/>
              </Scrollspy>
            </Collapse>
          </Container>
        <LanguageMenu className={'desktop_burger'}/>
        </nav>
      </span>
    </React.Fragment>);
};

export default NavBar;
