import { LOCALES } from "./locales";


export const translationKeys = {
    [LOCALES.AM]: {
        "address": "35 Լենինգրադյան փ., Երևան 0031",
        "contactUsText": "Այլ աճուրդ ընտրելու դեպքում, խնդրում ենք կապվել մեզ հետ՝",
        "others": "Այլ",
        "Car Price": "Մեքենայի Արժեք, USD",
        "Location": "Աճուրդի Վայր",
        "Shipping": "Տեղափոխման Վճար, USD",
        "Age": "Տարիք",
        "up to 3 years": "մինչև 3 տարի",
        "3-5 years": "3-5 տարի",
        "5-7 years": "5-7 տարի",
        "7+": "7+",
        "10+": "10+",
        "up to 5 years": "մինչև 5 տարի",
        "5-10 years": "5-10 տարի",
        "Fuel Type": "Շարժիչի Տեսակ",
        "Gasoline": "Բենզին",
        "Diesel": "Դիզել",
        "Full Electric": "Էլեկտրական",
        "Engine, cm³": "Շարժիչի Ծավալ, սմ³",
        "Vehicle Type": "Տրանսպորտի Տեսակ",
        "Offsite": "Աճուրդից դուրս",
        "Motorcycle": "Մոտոցիկլ",
        "Sedan": "Սեդան",
        "Pickup": "Պիկապ",
        "SUV": "Ամենագնաց",
        "calculate": "Հաշվել",
        "auction": "Աճուրդ",
        "Auction Fee": "Աճուրդի Միջնորդավճար",
        "Insurance": "Ապահովագրություն",
        "Service Fee": "Միջնորդավճար",
        "Customs Duty": "Մաքսատուրք",
        "VAT": "ԱԱՀ",
        "Eco Tax": "Բնապահպանական Հարկ",
        "Broker Fee": "Բրոքերային Ծառայություն",
        "total": "Ընդհանուր",
        "paymentWidthin1Days": "Ենթակա է վճարման մեքենան գնելուց հետո 1(մեկ) աշխատանքային օրվա ընթացքում",
        "paymentWidthin45Days": "Պայմանագրի կնքմանը հաջորդող 45(քառասունհինգ) օրվա ընթացքում",
        "paymentWidthin3Days": "Ավտոﬔքենայի ներմուծման վերաբերյալ ծանուցումը ստանալուց հետո 3 (երեք) օրվա ընթացքում",
        "download": "Ներբեռնել",
        "sendToEmail": "Ուղարկել Էլ. Հասցեին",
        "formMessage": "Համոզվեք, որ բոլոր դաշտերը լրացված են",
        "emailAddress": "Էլ․ հասցե",
        "successMessage": "Ձեր նամակն ուղարկվել է",
        "errorMessage": "Սխալ փոստ",
        "Based on Select Location": "Հիմնված տեղանքի ընտրության վրա",
        "OTHERS": "ԱՅԼ",
        "close": "Փակել",
        "selectManufactureYearError": 'Խնդրում ենք կապնվեք մեզ հետ տվյալ տարիքի մեքենա ներկրելու համար',
        calculator: 'Հաշվիչ',
        services: 'Ծառայություններ',
        ourProcess: 'Ծառայության Քայլերը',
        aboutUs: 'Մեր մասին',
        contacts: 'Հետադարձ կապ',
        statistics: 'Վիճակագրություն',
        calculateOnlineWithoutOurSupport: 'Հաշվիր Օնլայն առանց մեր օգնության',
        ourOnlineCalc: 'Մեր Օնլայն հաշվիչը կօգնի հաշվարկել  մեքենայի վերջնական արժեքը Հայաստանում',
        ourServices: 'ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ',
        'TRUSTING US IS PROFITABLE, FAST AND SAFE': 'ՄԵԶ ՎՍՏԱՀԵԼԸ ՇԱՀԱՎԵՏ Է, ԱՐԱԳ ԵՎ ԱՊԱՀՈՎ',
        "Car Selection": 'Ավտոմեքենայի Ընտրություն',
        'Our experienced specialist...': 'Մեր փորձառու մասնագետները կցուցաբերեն անհատական մոտեցում ﬔքենաների ընտրության, հնարավոր ռիսկերի նվազեցման և արժեքի հաշվարկման ժամանակ։',
        'Vehicle History': 'Մեքենայի պատմություն',
        'Our specialists will check the history...': 'Մեքենայի Ընտրության փուլում՝ նախքան աճուրդին մասնակցելը, մեր մասնագետները կստուգեն մեքենայի պատմությունը (autocheck, carfax)։',
        'Auctions': 'Աճուրդին մասնակցություն',
        'Meta Motors is licensed...': 'Meta Motors ընկերությունը լիցենզավորված է մասնակցելու գրեթե բոլոր խոշոր ավտոաճուրդներին ԱՄՆ-ում և Կանադայում (Copart, IAAI, Manheim, Carmax, Cars.com…)։  Աճուրդին կարող եք մասնակցել՝ ինչպես ներկա գտնվելով մեր գրասենյակում, այնպես էլ ցանկացած այլ վայրից՝  հետևելով  աճուրդին Live ռեժիմով։',
        'Inland Transportation': 'Ցամաքային Փոխադրում',
        'We arrange the transportation of cars...': 'Մենք կազմակերպում ենք մեքենաների փոխադրում աճուրդից մոտակա նավահանգիստ, որտեղ  դրանք պատրաստվում են ծովային տեղափոխման։ Նավահանգիստ հասնելուն պես տրամադրվում են ավտոﬔքենայի հավելյալ  լուսանկարներ:',
        'We provide the cargo insurance...': 'Նավահանգստի նկարների հիման վրա կատարվում է տեղափոխման ապահովագրություն ՀՀ առաջատար ապահովագրական ընկերությունների ﬕջոցով, կամ հաճախորդի ցանկությամբ, այլ միջազգային ապահովագրական ընկերության միջոցով։',
        'Sea Transportation': 'Ծովային Փոխադրոմ',
        'The car is being loaded into a container...': 'Ավտոﬔքենան բեռնվում է կոնտեյների ﬔջ, որի հատուկ համարի օգնությամբ կարող եք ցանկացած պահի տեսնել, թե որտեղ է գտնվում Ձեր գույքը, և հետևել տեղափոխման ողջ ընթացքին:',
        'Final Inland Transportation': 'Վերջնական Ցամաքային Փոխադրում',
        'After reaching the final port...': 'Ձեր մեքենայով կոնտեյները վերջնական նավահանգիստ (Փոթի, Բաթումի) հասնելուց հետո, կնքված՝ փակ վիճակում, ցամաքային փոխադրմամբ կտեղափոխվի Հայաստան՝  Գյումրի քաղաքի մաքսային տերմինալ։',
        'Customs clearance': 'Մաքսազերծում',
        'Your vehicle is unloaded from the container...': 'Մեր մասնագետների ներկայությամբ ﬔքենան բեռնաթափվում է կոնտեյներից և մաքսազերծվում է ﬔր բրոքերային կազմակերպության ﬕջոցով, ինչից հետո այն պատրաստ է Ձեզ հանձնելուն։',
        'Renovation': 'Վերանորոգում',
        'Your car imported through Meta Motors...': 'Meta Motors-ի միջոցով ներկրված Ձեր մեքենան կարող է ամբողջովին վերանորոգվել մեր հմուտ և փորձառու մասնագետների կողմից, և Ձեզ հանձնվել լիովին պատրաստի վիճակում։',
        'Transportation within the EEU': 'Փոխադրում ԵԱՏՄ տարածքում',
        'We also transport cars from Armenia to different cities...': 'Իրականացնում ենք նաև մեքենաների փոխադրում ՌԴ և Ղազախստանի տարբեր քաղաքներ (Մոսկվա, Ռոստով, Կրասնոդար, Պետերբուրգ, Աստանա):',
        mostPreferredCars: 'Մեր հաճախորդների ամենահաճախ պատվիրված մեքենաները նախորդ տարում',
        processSectionTitle: 'Մեր ծառայություններից օգտվելը հեշտ է',
        theSequenceIsClear: 'Հերթականությունը պարզ է՝  ընթացքը թափանցիկ։',
        process1Title: 'Պայմանագիր',
        process1P: 'Կնքում ենք մեքենա ներկրելու մասին պայմանագիր և մուտքագրում եք վերադարձվող կանխավճարը։',
        process2Title: 'Աճուրդ',
        process2P: 'Ընտրում ենք մեքենան, հաղթում աճուրդը և կատարում  դրա վճարումը։',
        process3Title: 'Փոխադրում',
        process3P: 'Սպասում եք մինչև մեր մասնագետները կփոխադրեն մեքենան Հայաստան՝ վճարելով 30 օրվա ընթացքում:',
        process4Title: 'Մաքսազերծում',
        process4P: ' Կատարում եք մաքսային ձևակերպման վճարումը, մինչ մենք իրականացնում ենք մաքսազերծումը և հանձնում Ձեզ սպասված մեքենան։',
        aboutUsText1: 'META MOTORS -ը սկսել է իր գործունեությունը 2016 թ.-ին, և կարճ ժամանակում հասցրել է բարձր գնահատականի արժանանալ ՀՀ-ում և ՌԴ-ում։ Ընկերությունը հանդիսանում է Ամերիկայի խոշորագույն Insurance Auto Auction (IAA), COPART և Manheim ավտոաճուրդների լիցենզավորված մասնակիցը։ Ընկերությունը սպասարկում է ինչպես անհատական հաճախորդներին, այնպես էլ ավտոդիլերներին ՀՀ-ում և ՌԴ -ում։',
        aboutUsText2: 'Մեր ընկերությունը կարևորում է ճշտապահությունը և աշխատանքի բարձր որակը։ Հաճախորդների գոհունակությունը կարևոր տեղ է զբաղեցում մեր կողմից հետապնդվող նպատակներում։',
        aboutUsText3: 'Մեր առաքելությունն է հարմարավետություն մտցնել մեր հաճախորդների կյանք՝ օգնելով նրանց ստանալ առավելագույն հնարավորը նախատեսված բյուջեյով։',
        aboutUsText4: '2021 թվականից ընկերությունը ընդլայնել է իր ծառայությունների ցանկը, հաջողությամբ ներդնելով մեքենաների վերականգնման ծառայություն, որի օգնությամբ մեր հաճախորդները կարողանում են ստանալ ամբողջովին պատրաստի մեքենաներ՝ պատվիրելով աճուրդներից վնասված վիճակում։',
        aboutUsText5: 'Ընկերությունը երբեք կանգ չի առնում զարգացման մեջ և շարունակելու է ընդլայնել մատուցվող ծառայությունների ցանկը՝ անընդհատ աշխատելով որակի կատարելագործման ուղղությամբ։',
        sections: 'Բաժիններ',
        mainOffice: 'Գլխավոր գրասենյակ',
        officeAddress: 'Երևան, Չայկովսկու փ. 34',
        Phone: 'Հեռախոս',
        email: 'էլ. փոստ',
        contact: 'Կապ',
        WorkingHours: 'Աշխատանքային ժամեր',
        MondayFriday: 'Երկուշաբթի-ուրբաթ',
        'Saturday-Sunday: Holiday': 'Շաբաթ-կիրակի: ոչ աշխատանքային',
        getInTOUCH: 'Կապ հաստատել',
        name: 'Անուն',
        enterName: 'Մուտքագրեք ձեր անունը',
        enterEmail: 'Մուտքագրեք ձեր էլ. փոստը',
        enterPhone: 'Մուտքագրեք ձեր հեռախոսահամարը',
        comments: 'Մեկնաբանություն',
        enterComment: 'Թողեք մեկնաբանություն այստեղ',
        sendMessage: 'Ուղարկել հաղորդագրություն',
        auctionFee: 'Աճուրդի միջնորդավճար',
        transportationFee: 'Տեղափոխման վճար',
        commissionFee: 'Միջնորդավճար',
        airPollutionTax: 'Բնապահպանական հարկ',
        customsTax: 'Մաքսատուրք',
        brokerFee: 'Բրոքերային վճար',
        vat: 'ԱԱՀ',
    },
    [LOCALES.RU]: {
        "address": "Ул. Ленинградян 35, Ереван 0031",
        "contactUsText": "При выборе другого аукциона, пожалуйста свяжитесь с нами:",
        "others": "Другое",
        "Car Price": "Стоимость Авто, USD",
        "Location": "Выберите Город",
        "Shipping": "Доставка, USD",
        "Age": "Возраст",
        "up to 3 years": "до 3 лет",
        "3-5 years": "3-5 лет",
        "5-7 years": "5-7 лет",
        "7+": "7+",
        "10+": "10+",
        "up to 5 years": "до 5 лет",
        "5-10 years": "5-10 лет",
        "Fuel Type": "Тип Двигателя",
        "Gasoline": "Бензин",
        "Diesel": "Дизель",
        "Full Electric": "Электрический",
        "Engine, cm³": "Объем, см³",
        "Vehicle Type": "Вид Транспорта",
        "Offsite": "Вне офиса",
        "Motorcycle": "Мотоцикл",
        "Sedan": "Седан",
        "Pickup": "Пикап",
        "SUV": "Внедорожник",
        "calculate": "Рассчитать",
        "auction": "Аукцион",
        "Auction Fee": "Аукционный Сбор",
        "Insurance": "Страхование",
        "Service Fee": "Наши Услуги",
        "Customs Duty": "Пошлина",
        "VAT": "НДС",
        "Eco Tax": "Эко. сбор",
        "Broker Fee": "Брокерские услуги",
        "total": "Всего",
        "paymentWidthin1Days": "В течение 1(одного) дня после покупки авто",
        "paymentWidthin45Days": "В течение 45 (сорока пяти) дней после заключения Договора",
        "paymentWidthin3Days": "В течение 3 (трех) дней, после получения уведомления о ввозе автомобиля",
        "download": "Скачать",
        "sendToEmail": "Отправить на эл. почту",
        "formMessage": "Убедитесь, что все поля заполнены",
        "emailAddress": "Эл. адрес",
        "successMessage": "Ваше письмо отправлено",
        "errorMessage": "Неправильная почта",
        "Based on Select Location": "На основе выбора местоположения",
        "OTHERS": "ДРУГОЕ",
        "close": "Закрыть",
        'selectManufactureYearError': 'Просим писать или звонить нам для машин такого года производство',
        calculator: 'Калькулятор',
        services: 'Услуги',
        ourProcess: 'Наш Процесс',
        aboutUs: 'О Компании',
        contacts: 'Контакты',
        statistics: 'Статистика',
        calculateOnlineWithoutOurSupport: 'Рассчитай Онлайн Без Нашей Помощи',
        ourOnlineCalc: 'Наш онлайн калькулятор поможет рассчитать стоимость вашей машины в Армении',
        ourServices: 'Наши Услуги',
        'TRUSTING US IS PROFITABLE, FAST AND SAFE': 'ДОВЕРЯТЬ НАМ ВЫГОДНО, БЫСТРО И НАДЕЖНО',
        "Car Selection": 'Подбор авто',
        'Our experienced specialist...': 'Наши опытные специалисты проявят индивидуальный подход при выборе вашей машины, снизив возможные риски предоставив максимально точный рассчет.',
        'Vehicle History': 'История автомобиля',
        'Our specialists will check the history...': 'На этапе подбора Автомобиля, перед участием в аукционе, наши специалисты проверят историю автомобиля (Autocheck, Carfax).',
        'Auctions': 'Участие в аукционе',
        'Meta Motors is licensed...': 'Meta Motors имеет лицензию на участие практически во всех крупных автомобильных аукционах США и Канады (Copart, IAAI, Manheim, Carmax, Cars.com). Вы можете принять участие в аукционе, находясь в нашем офисе или из любого другого места, следя за аукционом в режиме Live.',
        'Inland Transportation': 'Наземная перевозка',
        'We arrange the transportation of cars...': 'Организуем транспортировку автомобилей с аукциона в ближайший порт, где их готовят к морской перевозке. По прибытии в порт предоставляются дополнительные фото автомобиля.',
        'We provide the cargo insurance...': 'Страхование грузоперевозки на основании снимков порта осуществляется ведущими страховыми компаниями Республики Армения, либо, по желанию клиента, через другую международную страховую компанию.',
        'Sea Transportation': 'Морская перевозка',
        'The car is being loaded into a container...': 'Автомобиль загружается в контейнер с уникальным номером, с помощью которого вы в любой момент можете посмотреть, где находится ваша машина, и проследить за всем процессом перевозки.',
        'Final Inland Transportation': 'Доставка в таможеный терминал',
        'After reaching the final port...': 'После прибытия в конечный морской порт (Поти, Батуми), ваш автомобиль в запечатанном, закрытом контейнере, наземным транспортом перевозиться в Армению, на таможенный терминал города Гюмри.',
        'Customs clearance': 'Таможенное оформление',
        'Your vehicle is unloaded from the container...': 'В присутствии наших специалистов автомобиль выгружается из контейнера, проходит таможенную очистку через брокерскую организацию, после чего готова к передаче вам.',
        'Renovation': 'Ремонт',
        'Your car imported through Meta Motors...': 'Импортированный с помощью Meta Motors LLC автомобиль, может быть полностью отремонтирован нашими квалифицированными и опытными специалистами, доставлен вам в полностью готовом состоянии.',
        'Transportation within the EEU': 'Транспортировка в ЕАЭС',
        'We also transport cars from Armenia to different cities...': 'Meta Motors организует перевозку автомобилей в разные города России и Казахстана (Москва, Ростов, Краснодар, Санкт-Петербург, Астана).',
        mostPreferredCars: 'Наши клиенты предпочли эти машины в прошлом году.',
        processSectionTitle: 'Наш Процесс',
        theSequenceIsClear: 'Последовательность понятна, процесс прозрачен.',
        process1Title: 'Контракт',
        process1P: 'Подписываем договор на ввоз автомобиля и вносите возмещаемый депозит',
        process2Title: 'Аукцион',
        process2P: 'Выбираем автомобиль, побеждаем в аукционе и оплачиваем его.',
        process3Title: 'Транспортировка',
        process3P: 'Вы ждете, пока наши специалисты перевезут машину в Армению, оплатив перевозку в течение 30 дней.',
        process4Title: 'Таможенное оформление',
        process4P: 'Оплачиваете растаможку, пока наши брокеры делают таможенное оформление и вручат машину.',
        aboutUsText1: 'Компания Meta Motors LLC начала свою деятельность в 2016 году и за короткое время успела получить высокие оценки в Армении  и России. Компания является лицензированным участником крупнейших в США аукционов IAAI, COPART и  Manheim. Компания предоставляет услуги как физическим лицам, так и автодилерам в Армении и России.',
        aboutUsText2: 'Наша компания делает упор на пунктуальность и высокое качество работы. Удовлетворенность клиентов является важной целью, которую мы преследуем.',
        aboutUsText3: 'Наша миссия – привнести комфорт в жизнь наших клиентов, помогая им получить максимально возможное в пределах своего бюджета.',
        aboutUsText4: 'С 2021 года компания расширила перечень услуг, успешно внедрив услугу восстановления автомобилей, с помощью которой наши клиенты имеют возможность получать полностью готовые автомобили под ключ, заказывая их в аварийном состоянии с аукционов.',
        aboutUsText5: 'Компания никогда не останавливается в развитии и планирует дальше расширять перечень предоставляемых услуг, постоянно работая над совершенствованием качества.',
        sections: 'Разделы',
        mainOffice: 'Главный офис',
        officeAddress: 'Ереван, Ул. Чайковского 34',
        Phone: 'Телефон',
        email: 'Почта',
        contact: 'Контакт',
        WorkingHours: 'Рабочее время',
        MondayFriday: 'Понедельник-Пятница',
        'Saturday-Sunday: Holiday': 'Суббота-Воскресенье: выходной',
        getInTOUCH: 'Связаться',
        name: 'Имя',
        enterName: 'Введите свое имя',
        enterEmail: 'Введите адрес электронной почты',
        enterPhone: 'Введите ваш телефон',
        comments: 'Комментарии',
        enterComment: 'Оставьте комментарий здесь',
        sendMessage: 'Отправить сообщение',
        auctionFee: 'Аукционный Сбор',
        transportationFee: 'Доставка',
        commissionFee: 'Наши Услуги',
        airPollutionTax: 'Эко. сбор',
        customsTax: 'Пошлина',
        brokerFee: 'Брокерские услуги',
        vat: 'НДС',
    },
    [LOCALES.EN]: {
        "address": "35 Leningradyan St, Yerevan 0031",
        "contactUsText": "If You choose another auction, please contact us:",
        "others": "Others",
        "Car Price": "Car Price, USD",
        "Location": "Location",
        "Shipping": "Shipping, USD",
        "Age": "Age",
        "up to 3 years": "up to 3 years",
        "3-5 years": "3-5 years",
        "5-7 years": "5-7 years",
        "7+": "7+",
        "10+": "10+",
        "up to 5 years": "up to 5 years",
        "5-10 years": "5-10 years",
        "Fuel Type": "Fuel Type",
        "Gasoline": "Gasoline",
        "Diesel": "Diesel",
        "Full Electric": "Full Electric",
        "Engine, cm³": "Engine, cm³",
        "Vehicle Type": "Vehicle Type",
        "Motorcycle": "Motorcycle",
        "Sedan": "Sedan",
        "Pickup": "Pickup",
        "SUV": "SUV",
        "calculate": "Calculate",
        "auction": "Auction",
        "Auction Fee": "Auction Fee",
        "Insurance": "Insurance",
        "Service Fee": "Service Fee",
        "Customs Duty": "Customs Duty",
        "VAT": "VAT",
        "Eco Tax": "Eco Tax",
        "Broker Fee": "Broker Fee",
        "total": "Total",
        "paymentWidthin1Days": "Within 1(one) day after buying the vehicle",
        "paymentWidthin45Days": "Within 45 (forty five) days after signing the Agreement",
        "paymentWidthin3Days": "Within 3 (three) days, after receiving notification about the import of the vehicle",
        "download": "Download",
        "sendToEmail": "Send to e-mail",
        "formMessage": "Make sure all fields are filled in",
        "emailAddress": "Email address",
        "successMessage": "Your letter has been sent",
        "errorMessage": "Incorrect email",
        "Based on Select Location": "Based on Select Location",
        "OTHERS": "OTHERS",
        "close": "Close",
        "selectManufactureYearError": 'Please contact us for cars of specified manufactured year',
        calculator: 'Calculator',
        services: 'Services',
        ourProcess: 'Our Process',
        aboutUs: 'About Us',
        contacts: 'Contacts',
        statistics: 'Statistics',
        calculateOnlineWithoutOurSupport: 'Calculate online without our support',
        ourOnlineCalc: 'Our Online Calculator Will Help You Calculate The Price Of the Car In Armenia',
        ourServices: 'OUR SERVICES',
        'TRUSTING US IS PROFITABLE, FAST AND SAFE': 'TRUSTING US IS PROFITABLE, FAST AND SAFE',
        "Car Selection": 'Car Selection',
        'Our experienced specialist...': 'Our experienced specialists will show an individual approach , reducing possible risks and  calculating the costs during the selection process.',
        'Vehicle History': 'Vehicle History',
        'Our specialists will check the history...': 'Our specialists will check the history of the car (autocheck, carfax), in the Car Selection stage, before participating in the auction.',
        'Auctions': 'Auctions',
        'Meta Motors is licensed...': 'Meta Motors is licensed to participate in almost all major auto auctions in the United States and Canada (Copart, IAAI, Manheim, Carmax, Cars.com). You can participate in the auction by being present in our office or from any other place, following the auction in Live mode.',
        'Inland Transportation': 'Inland Transportation',
        'We arrange the transportation of cars...': 'We arrange the transportation of cars from the auction to the nearest port, where they are being prepared for sea transportation. Upon arrival at the port, additional photos of the car are provided',
        'We provide the cargo insurance...': 'We provide the cargo insurance by the leading insurance companies of the Republic of Armenia, Based on the pictures of the port, or through another international insurance company, at the request of our customers.',
        'Sea Transportation': 'Sea Transportation',
        'The car is being loaded into a container...': 'The car is being loaded into a container, which has a unique serial number. Using it,  you can see where your property is at any time, and follow the whole shipping process.',
        'Final Inland Transportation': 'Final Inland Transportation',
        'After reaching the final port...': 'After reaching the final port (Poti, Batumi), it will be transported by inland transportation to Armenia, to the customs terminal of Gyumri, without opening the container.',
        'Customs clearance': 'Customs clearance',
        'Your vehicle is unloaded from the container...': 'Your vehicle is unloaded from the container in the presence of our specialists, cleared through customs through our brokerage organization, and is is ready to be handed over to you.',
        'Renovation': 'Renovation',
        'Your car imported through Meta Motors...': 'Your car imported through Meta Motors can be completely repaired by our skilled և experienced specialists in Armenia and delivered to you in a fully “ready to go” condition.',
        'Transportation within the EEU': 'Transportation within the EEU',
        'We also transport cars from Armenia to different cities...': 'We also transport cars from Armenia to different cities of Russia and Kazakhstan (Moscow, Rostov, Krasnodar, St. Petersburg, Astana).',
        mostPreferredCars: 'Our customers preferred these Cars the most last year.',
        processSectionTitle: 'Our Process',
        theSequenceIsClear: 'The sequence is clear, the process is transparent.',
        process1Title: 'Contract',
        process1P: 'We sign a car import contract and enter the refundable deposit.',
        process2Title: 'Auction',
        process2P: 'Choose the car, win the auction and make the payment for it.',
        process3Title: 'Transportation',
        process3P: 'You are waiting for our specialists to transport the car to Armenia, paying within 30 days.',
        process4Title: 'Customs clearance',
        process4P: 'Make the customs clearance payment while we do the customs clearance and hand over the car.',
        aboutUsText1: 'META MOTORS started its activity in 2016, and in a short time it managed to receive high marks in Armenia և in Russia. The company is a licensed member in USA largest Insurance Auto Auction (IAA), COPART և Manheim. The company works both with individual customers and car dealers in Armenia and Russia.',
        aboutUsText2: 'Our company emphasizes punctuality and high quality work. Customer satisfaction plays an important role in the goals we pursue.',
        aboutUsText3: 'Our mission is to bring comfort to the lives of our customers, helping them to get the maximum possible.',
        aboutUsText4: 'From 2021, the company has expanded the list of services by successfully introducing a car restoration service, which helps our customers to receive fully restored cars by ordering them in damaged condition from auctions.',
        aboutUsText5: 'The company never stops developing and will continue to expand the list of services provided, constantly working to improve quality.',
        sections: 'Sections',
        mainOffice: 'Main Office',
        officeAddress: 'Yerevan, Chaykovski st. 34',
        Phone: 'Phone',
        email: 'Email',
        contact: 'Contact',
        WorkingHours: 'Working Hours',
        MondayFriday: 'Monday-Friday',
        'Saturday-Sunday: Holiday': 'Saturday-Sunday: Holiday',
        getInTOUCH: 'Get In Touch',
        name: 'Name',
        enterName: 'Enter your name',
        enterEmail: 'Enter your email',
        enterPhone: 'Enter your phone',
        comments: 'Comments',
        enterComment: 'Leave a comment here',
        sendMessage: 'Send Message',
        auctionFee: 'Auction Fee',
        transportationFee: 'Shipping',
        commissionFee: 'Service Fee',
        airPollutionTax: 'Air pollution tax',
        customsTax: 'Customs Duty',
        brokerFee: 'Broker fee',
        vat: 'VAT',
    }
}
