import { createSlice } from "@reduxjs/toolkit";

const ageOptions = [
    {
        label: "up to 3 years",
        value: new Date().getFullYear(),
        category: "Age"
    },
    {
        label: "3-5 years",
        value: new Date().getFullYear() - 4,
        category: "Age"
    },
    {
        label: "5-7 years",
        value: new Date().getFullYear() - 6,
        category: "Age"
    },
    {
        label: "7+",
        value: new Date().getFullYear() - 8,
        category: "Age"
    }
];

const ageOptionsForElectricalCars = [
    {
        label: "up to 5 years",
        value: new Date().getFullYear(),
        category: "Age"
    },
    {
        label: "5-10 years",
        value: new Date().getFullYear() - 6,
        category: "Age"
    },
    {
        label: "10+",
        value: new Date().getFullYear() - 11,
        category: "Age"
    }
];


const fuelTypeOptions = [

    {
        label: "Gasoline",
        value: "petrol",
        category: "Fuel Type"
    },
    {
        label: "Diesel",
        value: "diesel",
        category: "Fuel Type"
    },
    {
        label: "Full Electric",
        value: "electrical",
        category: "Fuel Type"
    }
];


const vehicleTypeOptions = [
    {
        label: "Motorcycle",
        value: "motorcycle",
        category: "Vehicle Type"

    },
    {
        label: "Sedan",
        value: "sedan",
        category: "Vehicle Type"

    },
    {
        label: "Pickup",
        value: "pickup",
        category: "Vehicle Type"

    },
    {
        label: "SUV",
        value: "suv",
        category: "Vehicle Type"

    }
];

const initialState = {
    ageOptions,
    ageOptionsForElectricalCars,
    fuelTypeOptions,
    vehicleTypeOptions,
    locationOptions: [],
}

const dataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {
        setCountriesList: (state, action) => {
            state.locationOptions = action.payload;
        }
    },
});

export const {setCountriesList} = dataSlice.actions;

export default dataSlice.reducer;

export const getCountryListData = () => dispatch => fetch(process.env.REACT_APP_API_URL + '/auction-state-city-fees').then(data => data.json()).then(req => {
    if (req.status === 200 && req.data) {
        const locationArr = [];

        Object.keys(req.data).map(auctionType => {
            locationArr[auctionType] = []
            Object.keys(req.data[auctionType]).map(state => {
             Object.keys(req.data[auctionType][state]).map(location => {
                 locationArr[auctionType].push({
                     label: `${state}-${location}`,
                     value: `${state}-${location}`,
                     price: req.data[auctionType][state][location],
                     category: 'location'
                 })
                })
            })
        })

        dispatch(setCountriesList(locationArr))
    }
});

export const descriptionOptionsSelector = (state) => {
    return {
        "location": state.locationOptions,
        "manufacturedYear": state.ageOptions,
        "engineType": state.fuelTypeOptions,
        "carType": state.vehicleTypeOptions,
    }
};
