import React from "react";
import {Col, Container, Row} from "reactstrap";

import img01 from "../assets/images/feature/img-01.png";
import img02 from "../assets/images/feature/img-02.png";
import img03 from "../assets/images/feature/img-03.png";
import img04 from "../assets/images/feature/img-04.png";
import img05 from "../assets/images/feature/img-05.png";
import img06 from "../assets/images/feature/img-06.png";
import {T} from "../localization/translate";
import styles from "./Contacts/Contacts.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faHistory, faGavel, faTruckMoving, faShip, faTruckLoading, faUserShield, faWrench, faTruck} from "@fortawesome/free-solid-svg-icons";

const ServicesCard = (props) => {
    return (
        <>
            <Col lg={4} md={6}>
                <div
                    className={
                        props.isShadow
                            ? "service-box text-center mt-4 box-shadow"
                            : "service-box text-center mt-4"
                    }
                >
                    <span className={styles.serviceIcon}><FontAwesomeIcon icon={props.icon} /></span>
                    <h5 className="fs-18 mt-4">{props.title}</h5>
                    <div className="lighlight-border mt-3"></div>
                    <p className="text-muted mt-3">{props.caption}</p>
                </div>
            </Col>
            {/* <!--end col--> */}
        </>
    );
}

// Services data

const ServicesData = () => [
    {
        imgsrc: img01,
        title: T('Car Selection'),
        caption: T('Our experienced specialist...'),
        icon: faSearch,
    },
    {
        imgsrc: img02,
        title: T('Vehicle History'),
        caption: T('Our specialists will check the history...'),
        isShadow: true,
        icon: faHistory,
    },
    {
        imgsrc: img03,
        title: T('Auctions'),
        caption: T('Meta Motors is licensed...'),
        icon: faGavel,
    },
    {
        imgsrc: img04,
        title: T('Inland Transportation'),
        caption: T('We arrange the transportation of cars...'),
        isShadow: true,
        icon: faTruckLoading,
    },
    {
        imgsrc: img05,
        title: T("Sea Transportation"),
        caption: T("The car is being loaded into a container..."),
        icon: faShip,
    },
    {
        imgsrc: img06,
        title: T('Final Inland Transportation'),
        caption: T("After reaching the final port..."),
        isShadow: true,
        icon: faTruckMoving,
    },
    {
        imgsrc: img06,
        title: T('Customs clearance'),
        caption: T('Your vehicle is unloaded from the container...'),
        icon: faUserShield,
    },
    {
        imgsrc: img06,
        title: T('Renovation'),
        caption: T('Your car imported through Meta Motors...'),
        isShadow: true,
        icon: faWrench,
    },
    {
        imgsrc: img06,
        title: T('Transportation within the EEU'),
        caption: T('We also transport cars from Armenia to different cities...'),
        icon: faTruck,
    },

];

const Services = () => {
    return (
        <>
            <section className="section" id="services">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={7}>
                            <div className="header-title text-center">
                                {/*<p className="text-uppercase text-muted mb-2">*/}
                                {/*  */}
                                {/*</p>*/}
                                <h3>{T('ourServices')}</h3>
                                <div className="title-border mt-3"/>
                                <p className="text-muted mt-3">
                                    {T('TRUSTING US IS PROFITABLE, FAST AND SAFE')}
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        {/* Services Component Start */}

                        {ServicesData().map((value, idx) => (
                            <ServicesCard
                                key={idx}
                                imgsrc={value.imgsrc}
                                title={value.title}
                                caption={value.caption}
                                isShadow={value.isShadow}
                                icon={value.icon}
                            />
                        ))}

                        {/* Services Component End */}
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Services;
