import React from "react";
import styles from "./PaymentTerms.module.scss";
import {T} from "../../localization/translate";

export default function PaymentTerms() {

    return (
        <div className={styles.infoWrapper}>
            <div className={styles.infoItem}>
                <div className={styles.infoColorFirst}></div>
                <div className={styles.infoText}>
                    {T("paymentWidthin1Days")}
                </div>
            </div>
            <div className={styles.infoItem}>
                <div className={styles.infoColorSecond}></div>
                <div className={styles.infoText}>
                    {T("paymentWidthin45Days")}
                </div>
            </div>
            <div className={styles.infoItem}>
                <div className={styles.infoColorThird}></div>
                <div className={styles.infoText}>
                    {T("paymentWidthin3Days")}
                </div>
            </div>
        </div>
    )
}
