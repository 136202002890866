import React from "react";
import {Col, Container, Row} from "reactstrap";
import {T} from "../localization/translate";

const ProcessCard = (props) => {
    return (
        <React.Fragment>
            <Col lg={3} md={6}>
                <div className="process-box text-center">
                    <div className="process-count text-white mt-4">
                        <h3 className="process-number">{props.number}</h3>
                    </div>

                    <div className="process-content bg-light mt-5 rounded p-4">
                        <h5 className="fs-18">{props.step}</h5>
                        <p className="text-muted mb-0">{props.caption}</p>
                    </div>
                </div>
                {/* <!--end process-box--> */}
            </Col>
        </React.Fragment>
    );
}

const Process = () => {
    const ProcessData = [
        {
            number: "01",
            step: T('process1Title'),
            caption: T('process1P'),
        },
        {
            number: "02",
            step: T('process2Title'),
            caption: T('process2P'),
        },
        {
            number: "03",
            step: T('process3Title'),
            caption: T('process3P'),
        },
        {
            number: "04",
            step: T('process4Title'),
            caption: T('process4P'),
        },
    ];

    return (
        <React.Fragment>
            <section className="bg-process section" id="process">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={7}>
                            <div className="header-title text-center">
                                <h3>{T('processSectionTitle')}</h3>
                                <div className="title-border mt-3"></div>
                                <p className="title-desc text-muted mt-3">{T('theSequenceIsClear')}</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5 pt-2">
                        {/* Process Component Start */}

                        {ProcessData.map((value, idx) => (
                            <ProcessCard
                                key={idx}
                                number={value.number}
                                step={value.step}
                                caption={value.caption}
                                isShow={value.isShow}
                            />
                        ))}

                        {/* Process Component End */}
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Process;
