import React from "react";
import { Col, Container, Row } from "reactstrap";

import speedometer from "../assets/images/aboutUs.jpg";
import {T} from "../localization/translate";

// const CounterData = [
//   {
//     endPoint: 485,
//     iconClass: "mdi mdi-heart",
//     title: "Happy Clients",
//   },
//   {
//     endPoint: 536,
//     iconClass: "mdi mdi-layers",
//     title: "Projects Completed",
//   },
//   {
//     endPoint: 1652,
//     iconClass: "mdi mdi-cloud-download",
//     title: "Files Downloaded",
//   },
//   {
//     endPoint: 14652,
//     iconClass: "mdi mdi-code-not-equal-variant",
//     title: "Liens Of Code",
//   },
// ];

const AboutUs = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id={'about'}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center mb-4">
                <h3 className="text-uppercase">{T('aboutUs')}</h3>
                <div className="title-border mt-3"/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="counter-box">
                <p className="text-muted mt-2">{T('aboutUsText1')}</p>
                <p className="text-muted mt-2">{T('aboutUsText2')}</p>
                <p className="text-muted mt-2">{T('aboutUsText3')}</p>
                <p className="text-muted mt-2">{T('aboutUsText4')}</p>
                <p className="text-muted mt-2">{T('aboutUsText5')}</p>

                {/*<div className="mt-4 mb-5">*/}
                {/*  <Row id="counter">*/}
                {/*    /!* Counter Component Start *!/*/}

                {/*    {CounterData.map((val, key) => (*/}
                {/*      <Col key={key} md={6}>*/}
                {/*        <div className="counter-box mt-4">*/}
                {/*          <div className="d-flex">*/}
                {/*            <div className="counter-icon">*/}
                {/*              <i className={val.iconClass}/>*/}
                {/*            </div>*/}
                {/*            <div className="flex-grow-1 ms-3">*/}
                {/*              <h4 className="counter_value" data-target="485">*/}
                {/*                <CountUp end={val.endPoint} duration={2} />*/}
                {/*              </h4>*/}
                {/*              <p className="text-muted">{val.title}</p>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </Col>*/}
                {/*    ))}*/}

                {/*    /!* Counter Component Start *!/*/}
                {/*  </Row>*/}
                {/*</div>*/}
              </div>
            </Col>

            <Col lg={6} className={'d-flex justify-content-center align-items-center'}>
              <div className="img">
                <img
                  src={speedometer}
                  className="img-fluid box-shadow-lg rounded"
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default AboutUs;
