import React from "react";
import Select from "../Select/Select";
import Input from "../Input/Input";
import styles from "./Calculator.module.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import SubmitButton from "../SubmitButton/SubmitButton";
import {useSelector} from "react-redux";
import {T} from "../../localization/translate";

export default function Calculator(props) {
    const {params, loading, handleChange, handleLocation, handleSubmit, auctionLabel} = props;
    const {
        locationOptions,
        ageOptions,
        fuelTypeOptions,
        vehicleTypeOptions,
        ageOptionsForElectricalCars
    } = useSelector(state => state.data);

    const defaultProps = {
        options: locationOptions?.[auctionLabel] || [],
        getOptionLabel: (option) => {
            return (option.label)
        }
    };

    console.log(props)
    return (
        <div className={styles.wrapper}>
            <form className={styles.form}>
                <div className={styles.inputWrapper}>
                    <TextField
                        id="standard-basic1"
                        label={T("Car Price")}
                        value={params.carPrice.value}
                        name="carPrice"
                        onChange={(e) => handleChange(e)}
                        className={styles.input}
                        type="number"
                    />
                    <Select
                        value={params.engineType.value}
                        name="engineType"
                        style={styles.input}
                        handleChange={(e) => handleChange(e, "engineType")}
                        label="Fuel Type"
                        options={fuelTypeOptions}
                    />
                    <Select
                      value={params.manufacturedYear?.value || ''}
                      name="manufacturedYear"
                      style={styles.input}
                      handleChange={(e) => handleChange(e, "manufacturedYear")}
                      label="Age"
                      options={ageOptions}
                      hidden={params.engineType.value === 'electrical'}
                    />
                    <Select
                      value={params.manufacturedYear?.value || ''}
                      name="manufacturedYear"
                      style={styles.input}
                      handleChange={(e) => handleChange(e, "manufacturedYear")}
                      label="Age"
                      options={ageOptionsForElectricalCars}
                      hidden={params.engineType.value !== 'electrical'}
                    />
                    <TextField
                        id="standard-basic2"
                        label={T("Engine, cm³")}
                        value={params.engineVolume.value}
                        name="engineVolume"
                        onChange={(e) => handleChange(e)}
                        className={styles.input}
                        disabled={params.engineType.value === fuelTypeOptions[2].value}
                        type="number"
                    />
                    <Autocomplete
                        {...defaultProps}
                        id="controlled-demo"
                        value={params.auctionVenue}
                        onChange={(event, newValue) => {
                            handleLocation(newValue);
                        }}
                        renderInput={(param) => {
                            return <TextField {...param} label={T("Location")}/>
                        }
                        }
                        className={styles.input}
                    />
                    <Select
                        value={params.carType.value}
                        name="carType"
                        style={styles.input}
                        handleChange={(e) => handleChange(e, "carType")}
                        label="Vehicle Type"
                        options={vehicleTypeOptions}
                    />
                    <Input
                        // disabled
                        value={params.shipping.value}
                        name="shipping"
                        style={`${styles.input} ${styles.inputDisabled}`}
                        label="Shipping"
                        placeholder="Based on Select Location"
                    />
                    <FormControlLabel
                      name="offsite"
                      control={<Checkbox onChange={handleChange} />}
                      label={T('Offsite')}
                      style={{height: '40px'}}
                    />
                </div>
                <SubmitButton
                    handleSubmit={handleSubmit}
                    isLoading={loading}
                    text={T("calculate")}
                    style={{marginTop: 20}}
                    className={styles.submitBtn}
                />
            </form>
        </div>
    )
}
