import React from "react";
import styles from "./Calculation.module.scss";
import {thousandSeparator} from "../../utils";
import {T} from "../../localization/translate";
import logoDark from "../../assets/images/metaMotors_Icon.png";

export default function Calculation(props) {
    const {data} = props;
    const {
        airPollutionTax,
        auctionFee,
        brokerFee,
        carPrice,
        commissionFee,
        customsTax,
        total,
        totalAMD,
        transportationFee,
        vat
    } = data;

    return (
        <div className={styles.wrapper}>
            <div className={styles.logo}>
                <img
                  src={logoDark}
                  className="logo-light"
                  alt=""
                  height="70"
                />
            </div>
            <div className={styles.calcTable}>
                <div className={styles.calcItem}>
                    <div className={styles.calcResultWrapper}>
                        <span className={styles.dotSpan}/>
                        <span className={`${styles.calcCategory} ${styles.paymentColorFirst}`}>
                            {T("Car Price")}
                                </span>
                        <span className={styles.calcValue}>{thousandSeparator(carPrice)}</span>
                        <span className={styles.currency}>&#36;</span>
                    </div>
                    <div className={styles.calcResultWrapper}>
                        <span className={styles.dotSpan}/>
                        <span className={`${styles.calcCategory} ${styles.paymentColorFirst}`}>
                            {T('auctionFee')}
                        </span>
                        <span className={styles.calcValue}>{thousandSeparator(auctionFee)}</span>
                        <span className={styles.currency}>&#36;</span>
                    </div>
                    <div className={styles.calcResultWrapper}>
                        <span className={styles.dotSpan}/>
                        <span className={`${styles.calcCategory} ${styles.paymentColorSecond}`}>
                           {T('transportationFee')}
                        </span>
                        <span className={styles.calcValue}>{thousandSeparator(transportationFee)}</span>
                        <span className={styles.currency}>&#36;</span>
                    </div>
                    <div className={styles.calcResultWrapper}>
                        <span className={styles.dotSpan}/>
                        <span className={`${styles.calcCategory} ${styles.paymentColorSecond}`}>
                           {T('commissionFee')}
                        </span>
                        <span className={styles.calcValue}>{thousandSeparator(commissionFee)}</span>
                        <span className={styles.currency}>&#36;</span>
                    </div>
                </div>
                <div className={styles.calcItem}>
                    <div className={styles.calcResultWrapper}>
                        <span className={styles.dotSpan}/>
                        <span className={`${styles.calcCategory} ${styles.paymentColorThird}`}>
                           {T('airPollutionTax')}
                        </span>
                        <span className={styles.calcValue}>{thousandSeparator(airPollutionTax)}</span>
                        <span className={styles.currency}>&#36;</span>
                    </div>
                    <div className={styles.calcResultWrapper}>
                        <span className={styles.dotSpan}/>
                        <span className={`${styles.calcCategory} ${styles.paymentColorThird}`}>
                          {T('customsTax')}
                        </span>
                        <span className={styles.calcValue}>{thousandSeparator(customsTax)}</span>
                        <span className={styles.currency}>&#36;</span>
                    </div>
                    <div className={styles.calcResultWrapper}>
                        <span className={styles.dotSpan}/>
                        <span className={`${styles.calcCategory} ${styles.paymentColorThird}`}>
                          {T('brokerFee')}
                        </span>
                        <span className={styles.calcValue}>{thousandSeparator(brokerFee)}</span>
                        <span className={styles.currency}>&#36;</span>
                    </div>
                    <div className={styles.calcResultWrapper}>
                        <span className={styles.dotSpan}/>
                        <span className={`${styles.calcCategory} ${styles.paymentColorThird}`}>
                          {T('vat')}
                        </span>
                        <span className={styles.calcValue}>{thousandSeparator(vat)}</span>
                        <span className={styles.currency}>&#36;</span>
                    </div>
                    {/*<div className={styles.calcResultWrapper}>*/}
                    {/*    <span className={`${styles.calcCategory} ${styles.paymentColorThird}`}>*/}
                    {/*        {translate(ecoTax.category)}*/}
                    {/*    </span>*/}
                    {/*    <span className={styles.calcValue}>{ecoTax.value}</span>*/}
                    {/*    <span className={styles.currency}>&#36;</span>*/}
                    {/*</div>*/}
                </div>
            </div>
            <h3 className={styles.metaText}>Meta Motors</h3>
            <div className={styles.priceWrapper}>
                <div className={styles.total}>{T("total")}</div>
                <div className={styles.priceContainer}>
                    <div className={styles.priceItem}>
                        <span className={styles.price}>{thousandSeparator(totalAMD)}</span>
                        <span className={styles.amdCurrency}>&#1423;</span>
                    </div>
                    <div className={styles.priceItem}>
                        <span className={styles.price}>{thousandSeparator(total)}</span>
                        <span className={styles.usdCurrency}>&#36;</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
