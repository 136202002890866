export function isValidEmail(str) {
    const regLetters = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    return regLetters.test(str);
}

export function containsOnlyNumbers(str) {
    const regLetters = /^[0-9]+$/;

    return regLetters.test(str);
}
