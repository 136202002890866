export default function checkValuesExist(params) {

    const valuesExist = Object.keys(params).every((key) => {
        const item = params[key];
        if (!item || !item.value || item.value[0] === "0"){
            return !!(key === 'engineVolume' && params.engineType && params.engineType.value === 'electrical');
        }
        return true;
    });

    return (valuesExist);

}
