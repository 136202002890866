import FormContacts from "./FormContacts/FormContacts";
import AuctionButtons from "./AuctionButtons/AuctionButtons";
import SocialNetworkBar from "./SocialNetworkBar/SocialNetworkBar";
import CustomAlert from "./CustomAlert/CustomAlert";
import React, {useState} from "react";
import {T} from "../localization/translate";

const CalculatorSection = (props) => {
    const [ auctionLabel, setAuctionLabel ] = useState("iaai");
    const [ showCalculator, setShowCalculator ] = useState(true);
    const [ successMessage, setSuccessMessage ] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });
    const [ emailErrorMessage, setEmailErrorMessage ] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });

    const [ openDrawer, setOpenDrawer ] = React.useState(false);
    const { language, handleChangeLanguage } = props;

    const handlShowCalculator = (label) => {
        setShowCalculator(true);
        setAuctionLabel(label);
    };

    const handleShowConatcts = (label) => {
        setShowCalculator(false);
        setAuctionLabel(label);
    };

    const handleSuccessMessage = (open) => {
        setSuccessMessage({ ...successMessage, open });
    };

    const handleEmailErrorMessage = (open) => {
        setEmailErrorMessage({ ...emailErrorMessage, open });
    };

    const toggleDrawer = (open) => {
        setOpenDrawer(open);
    };

    return (
        <>
            <section id={'calculator'} className={'section bg-light'} style={{position: 'relative'}}>
                <div className="header-title text-center" style={{marginBottom: 40}}>
                    {/*<p className="text-uppercase text-muted mb-2">*/}
                    {/*    Services We're Provided*/}
                    {/*</p>*/}
                    <h3>{T('calculator')}</h3>
                    <div className="title-border mt-3"/>
                    <p className="text-muted mt-3">{T('ourOnlineCalc')}</p>
                </div>
                {/*<Header handleChangeLanguage={handleChangeLanguage}*/}
                {/*        language={language}*/}
                {/*        toggleDrawer={toggleDrawer}*/}
                {/*        open={openDrawer}*/}
                {/*/>*/}
                <div style={{position: 'relative'}}>
                    <AuctionButtons
                        handlShowCalculator={handlShowCalculator}
                        handleShowConatcts={handleShowConatcts}
                        auctionLabel={auctionLabel}
                    />
                    {/*<Image src={car} alt="car" className={styles.imgWrapper} />*/}
                    <FormContacts
                        showCalculator={showCalculator}
                        handleSuccessMessage={handleSuccessMessage}
                        handleEmailErrorMessage={handleEmailErrorMessage}
                        auctionLabel={auctionLabel}
                    />
                </div>
            </section>
            {/*<SocialNetworkBar />*/}
            {
                successMessage.open &&
                <CustomAlert onClose={handleSuccessMessage}
                             vertical={successMessage.vertical}
                             horizontal={successMessage.horizontal}
                             message="successMessage"
                             severity="success"
                             millisecond={3500}
                />
            }
            {
                emailErrorMessage.open &&
                <CustomAlert onClose={handleEmailErrorMessage}
                             vertical={emailErrorMessage.vertical}
                             horizontal={emailErrorMessage.horizontal}
                             message="errorMessage"
                             severity="error"
                             millisecond={3500}
                />
            }
        </>
    )
}

export default CalculatorSection;
